<template>
    <div>
        <Navbar page="Suporte" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <h2 class="text-2xl mb-3 font-semibold">Informações sobre Suporte</h2>
            <div class="grid grid-cols-12 gap-6 mt-4">
                <div class="col-span-12 md:col-span-2 flex items-center">
                    <h2 class="text-lg mb-3 font-semibold">O que é?</h2>
                </div>
                <div class="col-span-12 md:col-span-10 border-l border-gray-800 px-2 py-2">
                    <p class="text-base font-normal">
                        Canal para que assinantes tirem dúvidas sobre a plataforma, opinem, reclamem ou deêm sugestões (nós adoramos sugestões)
                    </p>
                </div>
                <div class="col-span-12 md:col-span-2 flex items-center">
                    <h2 class="text-lg mb-3 font-semibold">Como solicitar?</h2>
                </div>
                <div class="col-span-12 md:col-span-10 border-l border-gray-800 px-2 py-2">
                    <p class="text-base font-normal">
                        Clique no botão abaixo e fale diretamente com nosso canal de suporte, se estivermos muito ocupados talvez a gente demore alguns minutos para responder, mas já já responderemos. Promessa é dívida!
                    </p>
                </div>
            </div>
            <hr class="mt-8 mb-4 border-gray-800" />
            <a target="_blank" href="https://api.whatsapp.com/send?phone=554299021266" type="button" class="inline-flex justify-center py-2 px-4 my-2 mx-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                Entre em contato por whatsapp
            </a>

            <a target="_blank" href="mailto:contato@facillgpd.com.br" type="button" class="inline-flex justify-center py-2 px-4 my-2 mx-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                Entre em contato por email
            </a>

            <hr class="mt-5"/>
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 md:col-span-5">
                    <h2 class="text-2xl mb-3 mt-6 font-semibold">Caixinha de sugestões</h2>
                    <p class="text-lg font-normal">
                        Críticas, sugestões ou elogios são sempre bem vindos. Que tal deixar a sua?
                    </p>
                    <textarea v-model="form.obs" rows="4" type="text" name="obs" id="obs" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                    <button @click="novaSolicitacao" type="button" class="inline-flex justify-center py-2 px-6 mt-5 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-500">
                        Enviar Sugestão
                    </button>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                tipo: 'Sugestão / Feedback',
                obs: ''
            }
        }
    },
    methods: {
        async novaSolicitacao (){
            const req = await this.$http.post(`/v1/solicitacao`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Solicitação enviada com sucesso!");
                this.form = { obs: ''};
            } else {
                this.$vToastify.error(req.data.err);
            }
        }
    }
}
</script>